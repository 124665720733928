// ----------------------------
// Fonts
// ----------------------------

$question-guide-small: 1rem;
$font-size-regular: 1.25rem;
$title-small: 1.6rem;
$title-large: 2rem;
$radioBtnHint: 0.7rem;

$font-weight: 700;

// ----------------------------
// Screens
// ----------------------------

$tablet: 767px;
$desktop: 1024px;

// ----------------------------
// Question boxes: Desktop
// ----------------------------

$min-height: 50px;
$padding: 20px;
$width: 300px;
$altBoxSize: 100px;

// ----------------------------
// Question boxes: phone
// ----------------------------

$width-phone: 100%;

// ----------------------------
// Button
// ----------------------------

$button-border-radius: 10px;

// ----------------------------
// Layout-sizes
// ----------------------------

$max-width: 600px;
$height: 90vh;
