@import "../style/theme.scss";

.thermostat-container {
  display: flex;
  flex-direction: column;

  h2,
  h3 {
    width: 200px;
    text-align: center;
  }
  span {
    display: flex;
    flex-direction: column;

    .thermostat-img {
      height: 250px;
      align-self: center;
    }
  }
}
