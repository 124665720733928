@import "../style/theme.scss";

.summary-view-container {
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: $tablet) {
    padding: 20px 0;
  }

  button {
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: $brown;
    font-size: $font-size-regular;
    font-weight: $font-weight;
    text-decoration: underline;
    cursor: pointer;

    img {
      padding: 5px;
      background-color: $brown;
      border-radius: 50%;
      height: 40px;
      margin-right: 20px;
      -webkit-transform: rotateX(180deg);
      transform: rotateY(180deg);

      @media (max-width: $tablet) {
        display: none;
      }
    }
  }
}
