@import "../style/theme.scss";

.survey-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .subcategory-container {
    align-items: flex-end;
    display: flex;
    flex-direction: column;

    @media (max-width: $tablet) {
      align-items: center;
    }
  }
}
