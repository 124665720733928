@import "../style/colors.scss";

.btn {
  display: inline-block;
  background-color: $brown;
  text-decoration: none;
  padding: 15px 40px;
  cursor: pointer;
  border: none;
  border-radius: $button-border-radius;
  transition: background-color 300ms ease-out;
  color: white;
  font-weight: $font-weight;
  text-transform: uppercase;
  margin: 10px 0;

  &.inactive {
    background-color: $darkGrey;
    cursor: not-allowed;
  }
}
