@import "../style/theme.scss";

.tool-box-container {
  background-color: $grey;
  padding: 20px;
  margin: 20px 0;
  border-radius: $button-border-radius;

  span {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $desktop) {
      flex-direction: column;
    }

    div {
      background-color: $green;
      border-radius: $button-border-radius;
      margin: 20px 10px 0 0;
      padding: 20px;
      width: auto;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 700;

      @media (max-width: $desktop) {
        margin: 10px 0;
      }
    }
  }
}
