@import "../style/theme.scss";

.welcome-info-screen-container {
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $height;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $max-width;

    > * {
      margin: 20px 0;

      @media (max-width: $tablet) {
        margin: 15px 10px;
      }
    }
  }

  img {
    height: 380px;

    @media (max-width: $tablet) {
      height: 170px;
    }
  }
}
