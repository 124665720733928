@import "../style/theme.scss";

.introduction-page-content {
  display: flex;
  padding: 50px;
  align-items: center;

  @media (max-width: $tablet) {
    flex-direction: column;
    padding: 0px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: $tablet) {
      align-items: center;
    }
  }

  > * {
    margin: 10px 50px;

    @media (max-width: $tablet) {
      margin: 10px 20px;
    }
  }

  .intro-page-img {
    height: 600px;

    @media (max-width: $tablet) {
      height: 300px;
    }
  }

  h2,
  p {
    max-width: 300px;
    color: $brown;

    @media (max-width: $tablet) {
      text-align: center;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: $title-large;

    @media (max-width: $tablet) {
      font-size: $title-small;
    }
  }
}
