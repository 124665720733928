@import "../style/theme.scss";

.question-container {
  display: flex;
  font-weight: $font-weight;
  color: $brown;

  @media (max-width: $tablet) {
    flex-direction: column;
  }

  span {
    display: flex;
  }

  .question-box {
    margin: 5px 5px 0 0;
    min-height: $min-height;
    display: flex;
    padding: $padding;
    background-color: $grey;
    width: $width;

    @media (max-width: $tablet) {
      width: auto;
      margin: 0;
    }
  }

  .radio-container {
    width: 145px;
    display: flex;
    justify-content: center;
    align-items: center;

    .radio-btn-tip {
      @media (min-width: $tablet) {
        display: none;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 50px;
      width: 50px;
    }

    .checkmark {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #aeaeae;
      display: inline-block;
    }
  }

  .radio-container input:checked ~ .checkmark {
    background-color: $brown;
  }
}
