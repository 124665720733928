@import "../style/theme.scss";

.results-container {
  color: $brown;
  .main-category-title {
    font-size: $title-small;
    text-align: center;
    padding: 10px 0;
    border-radius: $button-border-radius;

    &.green {
      background-color: $greenOverlay;
    }

    &.red {
      background-color: $redOverlay;
    }

    &.blue {
      background-color: $blueOverlay;
    }
  }

  h2 {
    font-size: $font-size-regular;
  }

  h3 {
    font-size: $title-large;
  }
  div {
    padding: 15px;
    border-radius: $button-border-radius;
    font-weight: 700;

    img {
      align-self: flex-start;
    }

    &.green {
      background-color: $greenOverlay;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.red {
      background-color: $redOverlay;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.blue {
      background-color: $blueOverlay;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  span {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: $desktop) {
      flex-direction: column;
    }

    .inner-toolbox {
      border-radius: $button-border-radius;
      margin: 20px 10px 0 0;
      padding: 20px;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 700;
      width: 200px;

      &.green {
        background-color: $green;
      }

      &.red {
        background-color: $red;
      }

      &.blue {
        background-color: $blue;
      }

      @media (max-width: $desktop) {
        margin: 10px 0;
      }
    }
  }
}
