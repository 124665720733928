@import "../style/theme.scss";

.progression-bar-container {
  display: flex;

  @media (max-width: $tablet) {
    display: none;
  }

  .background-line {
    width: 400px;
    background: $darkGrey;
    position: absolute;
    top: 38px;
    height: 5px;
    z-index: -1;
    margin-left: 30px;
  }

  ul {
    display: flex;
    list-style-type: none;
    padding: 0;

    li {
      background-color: $darkGrey;
      width: 20px;
      height: 20px;
      margin: 15px;
      border-radius: 50%;

      &.active {
        height: 25px;
        width: 25px;
        margin: 11px;
      }

      &.green {
        background-color: $green;

        &.active {
          box-shadow: 0px 0px 20px green;
        }
      }

      &.red {
        background-color: $red;

        &.active {
          box-shadow: 0px 0px 20px red;
        }
      }

      &.blue {
        background-color: $blue;

        &.active {
          box-shadow: 0px 0px 20px blue;
        }
      }
    }
  }
}
