@import "../style/theme.scss";

.tool-toggle-container {
  display: flex;
  align-items: center;

  h2 {
    color: $darkGrey;
    cursor: pointer;

    &.active {
      color: $brown;
      text-decoration: underline;
      text-decoration-color: $red;
    }
  }

  div {
    width: 2px;
    height: 25px;
    background-color: $brown;
    margin: 0 20px;
  }
}
