@import "../style/theme.scss";

.login-screen-container {
  display: flex;
  justify-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $height;

  @media (max-width: $tablet) {
    height: auto;
  }

  div,
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $max-width;
    margin: 30px 0;

    @media (max-width: $tablet) {
      width: auto;
      margin: 15px;
    }
  }

  input {
    width: $max-width - 15px;
    margin: 0 0 20px 0;
    border-radius: $button-border-radius;
    border: 2px solid $red;
    padding: 10px 5px;

    @media (max-width: $tablet) {
      width: 230px;
    }

    @media (max-width: 293px) {
      width: auto;
    }
  }
}
