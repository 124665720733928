@import "../style/theme.scss";

.welcome-screen-container {
  display: flex;
  width: 100%;
  height: $height;
  align-items: center;
  justify-content: center;

  header {
    @media (min-width: $tablet) {
      display: none;
    }

    img {
      height: 50px;
      padding: 5px;
    }
  }

  @media (max-width: $tablet) {
    height: auto;
    margin: 10px;
    width: auto;
  }

  div {
    max-width: $max-width;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    height: 120px;
    margin: 30px 0;
  }

  p {
    margin: 50px 0;
    b {
      color: $red;
    }
  }
}
footer {
  .av-logo {
    height: 50px;
    padding-left: 100px;

    @media (max-width: $tablet) {
      display: none;
    }
  }
}
