@import "../style/theme.scss";

.question-guide-container {
  display: flex;
  align-items: center;

  h2,
  div {
    padding: $padding;
    align-items: center;
    margin: 0 5px 0 0;
    font-size: $question-guide-small;
    font-weight: $font-weight;
    min-height: $min-height;
    display: flex;
    justify-content: center;
    color: white;

    @media (max-width: $tablet) {
      margin: 0;
    }
  }

  h2 {
    background-color: $brown;
    width: $width;

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  .question-alternatives {
    width: $altBoxSize;
    margin-right: 5px;
    text-align: center;

    @media (max-width: $tablet) {
      display: none;
    }

    &.blue {
      background-color: $blue;
    }

    &.red {
      background-color: $red;
    }

    &.green {
      background-color: $green;
    }
  }
}
