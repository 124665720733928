@import "../style/theme.scss";

.total-results-container {
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .results-thermostat-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $tablet) {
      flex-direction: column;
      padding: 0px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px;

      h3 {
        max-width: 200px;
        text-align: center;
        height: 50px;
      }

      h4 {
        font-size: $title-large;
      }

      img {
        height: 300px;
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
    button {
      display: flex;
      align-items: center;
      background-color: transparent;
      border: none;
      color: $brown;
      font-size: $font-size-regular;
      font-weight: $font-weight;
      text-decoration: underline;
      cursor: pointer;

      img {
        background-color: $brown;
        border-radius: 50%;
        height: 40px;
        margin-left: 20px;

        @media (max-width: $tablet) {
          margin: 0;
        }
      }
    }
  }
}
