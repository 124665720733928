$green: rgb(87, 160, 132);
$red: rgb(207, 98, 108);
$blue: rgb(90, 112, 136);
$brown: rgb(61, 55, 46);
$grey: rgb(232, 232, 232);
$darkGrey: #c9c9c9;

$greenOverlay: rgba(87, 160, 132, 0.25);
$redOverlay: rgba(207, 98, 108, 0.25);
$blueOverlay: rgba(90, 112, 136, 0.25);
